





















































































































































import { NO_TIER } from '@/constants'
import { Observer } from 'mobx-vue'
import { Component, Inject, Vue } from 'vue-property-decorator'
import { StakingViewModel } from '../viewmodels/staking-viewmodel'

@Observer
@Component({})
export default class StakingLPDialog extends Vue {
  @Inject() vm!: StakingViewModel
  noTierText = NO_TIER
}
